
	var slider = require("./slider");

	var isSmall = function() {
		return $(window).width() < 678;
	};

/* ==========================================================================
	Site Header
	========================================================================= */

	if (!isSmall()) {
		$(".home .site-header").scrollClass({
			className: "alt",
			condition: function(scroll) {
				return $(this).outerHeight() > scroll;
			}
		})  
		
	/* ==========================================================================
		Scroll Top
		========================================================================= */

		$(".back-to-top").on("click", function(event) {
			event.preventDefault();
			$("html").elScroll();
		});
	
	/* ==========================================================================
		Project Grid Scroll
		========================================================================= */

		$(".project-scroll").on("click", function(event) {
			event.preventDefault();
			$(".masthead-info-bar").elScroll({
				// 123 = @header-height less variable
				offset: $(".site-header").offset().top + 123 - $(".masthead-info-bar").outerHeight()
			});
		});
	}

/* ==========================================================================
	Slider
	========================================================================= */

	$(".slider-trigger").on("click", slider);
	$(".slider-close").on("click", slider);
	$(".slider-open").on("click", slider);

/* ==========================================================================
	Collapse // Bootstrap sucks
	========================================================================= */

	$(".collapse").on("click", ".collapse-trigger", function(event) {
		event.preventDefault();
		var $collapse = $(this).closest(".collapse");
		var $parent   = $( $collapse.data("collapse-parent") );
		var $panel    = $collapse.find(".collapse-panel");

		if ($parent.length && $collapse.hasClass("active")) {
			return;
		}

		$parent.length && $parent.find(".active").removeClass("active");
		$collapse.addClass("active");
	})

/* ==========================================================================
	Mobile
	========================================================================= */

	if (isSmall()) {
		$(".collapsing-nav-trigger").on("click", function(event) {
			event.preventDefault();
			$(this).closest(".collapsing-nav").toggleClass("open");
		});	
	}

/* ==========================================================================
	Gravity Forms Submission
	========================================================================= */

	jQuery(document).on('gform_confirmation_loaded', function(event, formId) {
		formId === 2 && $(".form-intro").remove();
	});

/* ======================================================================
	IE Grayscale
	===================================================================== */

	if (isIE10 || isIE11) {
		$(".grayscale").imagesLoaded( function() {
			$(".grayscale").css("visibility", "visible");
		});
	
		$(".team-item").on("toggleClass", function(e) {
			$img  = $(this).find(".grayscale");
			
			if ($(this).hasClass("open")) {
				$img.addClass("grayscale-off");
			} else {
				$img.removeClass("grayscale-off");
			}
		})

		$(".team-item").on("removeClass", function(e) {
			$img  = $(this).find(".grayscale");
			$img.removeClass("grayscale-off");
		})	
	}

/* ==========================================================================
	iOS Safari Detection
	========================================================================= */

	Modernizr.addTest('ipad', function () {
		return !!navigator.userAgent.match(/iPad/i);
	});

	Modernizr.addTest('iphone', function () {
		return !!navigator.userAgent.match(/iPhone/i);
	});

	Modernizr.addTest('ipod', function () {
		return !!navigator.userAgent.match(/iPod/i);
	});

	Modernizr.addTest('appleios', function () {
		return (Modernizr.ipad || Modernizr.ipod || Modernizr.iphone);
	});